import React from 'react';
import { FieldErrors, UseFormMethods } from 'react-hook-form';

import { Field, Input } from '@grafana/ui';

import { FormErrors } from 'utils/consts';
import { validateURL } from 'utils/misc';

interface Props {
  errors: FieldErrors;
  register: UseFormMethods['register'];
}
export const MetricsURLField = ({ register, errors }: Props) => {
  return (
    <Field label="Scrape Job URL" invalid={errors.url !== undefined} error={errors.url?.message}>
      <Input
        placeholder="https://my-example-url.com:9999/metrics"
        id="url"
        name="url"
        data-testid="url-input-field"
        ref={register({
          required: {
            value: true,
            message: FormErrors.REQUIRED_FIELD,
          },
          validate: {
            isHTTP: validateURL,
          },
        })}
      />
    </Field>
  );
};
