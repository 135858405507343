import { css } from '@emotion/css';
import React from 'react';
import { useForm } from 'react-hook-form';

import { GrafanaTheme2 } from '@grafana/data';
import { Field, Input, useStyles2 } from '@grafana/ui';

import { AppO11yTracesTestButton } from './AppO11yTracesTestButton';

export const useServiceInfo = (
  { includeServiceInstanceId }: { includeServiceInstanceId: boolean } = { includeServiceInstanceId: false }
) => {
  const styles = useStyles2(getStyles);

  const { register, watch, errors, trigger } = useForm({
    mode: 'onChange',
    defaultValues: {
      serviceName: undefined,
      serviceNamespace: undefined,
      serviceVersion: undefined,
      serviceInstanceId: undefined,
    },
  });

  const { serviceName, serviceNamespace, serviceVersion, serviceInstanceId } = watch([
    'serviceName',
    'serviceNamespace',
    'serviceVersion',
    'serviceInstanceId',
  ]);

  return {
    serviceName,
    serviceNamespace,
    serviceVersion,
    serviceInstanceId,

    form: (
      <div className={styles.serviceInfo}>
        <Field
          label="Service name"
          description="The name of your application"
          required
          invalid={errors.serviceName !== undefined}
          error={errors.serviceName ? 'Enter a valid serviceName' : ''}
        >
          <Input id="serviceName" name="serviceName" placeholder="Service name" ref={register({ required: true })} />
        </Field>

        <Field
          label="Service namespace"
          description="The namespace for your application, helps to distinguish a group of services"
        >
          <Input id="serviceNamespace" name="serviceNamespace" placeholder="Service namespace" ref={register()} />
        </Field>

        <Field label="Service version" description="The version string of the service API or implementation">
          <Input id="serviceVersion" name="serviceVersion" placeholder="Service version" ref={register()} />
        </Field>

        {includeServiceInstanceId && (
          <Field label="Service instance ID" description="Hostname or Kubernetes pod of your application">
            <Input id="serviceInstanceId" name="serviceInstanceId" placeholder="Service instance ID" ref={register()} />
          </Field>
        )}
      </div>
    ),

    testButton: (
      <AppO11yTracesTestButton serviceName={serviceName} serviceNamespace={serviceNamespace} onClick={trigger} />
    ),
  };
};

function getStyles(theme: GrafanaTheme2) {
  return {
    serviceInfo: css`
      display: flex;
      flex-direction: column;
    `,
  };
}
