import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

import { colors } from 'utils/consts';

export const getUninstallStyles = (theme: GrafanaTheme2) => ({
  overrideModal: css`
    max-width: 600px;
    padding: 6px;
  `,
  modalContent: css`
    h3 {
      margin-bottom: ${theme.spacing(4)};
      padding-top: ${theme.spacing(2)};
      font-size: ${theme.typography.h4.fontSize};
    }
    button {
      margin-right: ${theme.spacing(1)};
    }
  `,
  icon: css`
    margin-right: 10px;
  `,
  text: css`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #ccccdc;
    padding-left: 30px;
    margin-top: 15px;
  `,
  buttons: css`
    display: flex;
    justify-content: flex-end;
    margin-top: 100px;
  `,
  paragraph: css`
    margin-top: 15px;

    a {
      color: ${colors.blue11};
    }
  `,
});
