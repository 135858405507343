import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Spinner, useStyles2 } from '@grafana/ui';

import { useCreateOrUpdateScrapeJobMutation } from 'api/hostedExporters/hostedExportersApi';
import { Pages } from 'e2eSelectors/pages';
import { MetricsEndpointFlavor, SupabaseJob } from 'models/api-models';
import { CREATE_OR_UPDATE_JOBS_CACHE_KEY } from 'utils/consts';

import { CreateJobFormProps } from '../../SaasIntegrationWithScrapeJobs/JobManager/JobManager';
import { JobStatusWidget } from '../../SaasIntegrationWithScrapeJobs/JobManager/JobStatusWidget';
import { getInstructionsStyles, getJobFormStyles } from '../common/MetricsEndpointInstructions.styles';
import { ScrapeIntervalField } from '../common/ScrapeIntervalField';
import { ScrapeJobNameField } from '../common/ScrapeJobNameField';

import AuthField from './AuthField';

type ReactHookFormProps = {
  scrapeIntervalSeconds: number;
  name: string;
  apiKey: string;
  projectId: string;
};

export const CreateJob: FC<CreateJobFormProps> = ({ onSaveJob }) => {
  const jobStyles = useStyles2(getJobFormStyles);
  const styles = useStyles2(getInstructionsStyles);
  const [connectionSuccess, setConnectionSuccess] = useState(false);
  const [_, { status: createJobStatus }] = useCreateOrUpdateScrapeJobMutation({
    fixedCacheKey: CREATE_OR_UPDATE_JOBS_CACHE_KEY,
  });
  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ReactHookFormProps>({
    mode: 'onChange',
    defaultValues: {
      scrapeIntervalSeconds: 60,
      projectId: '',
      apiKey: '',
      name: '',
    },
  });

  const handleCreate = handleSubmit((currentJob: SupabaseJob) => {
    onSaveJob({
      name: currentJob.name,
      enabled: true,
      scrape_interval_seconds: currentJob.scrapeIntervalSeconds,
      flavor: MetricsEndpointFlavor.Supabase,
      supabase: {
        project_id: currentJob.projectId,
        api_key: currentJob.apiKey,
      },
    });
  });

  return (
    <form onSubmit={handleCreate}>
      <ol className={styles.instructions}>
        <li className={styles.instructionItem}>
          <h2>Give your Scrape Job a name</h2>
          <ScrapeJobNameField register={register} errors={errors} />
        </li>
        <li className={styles.instructionItem}>
          <h2>Enter the Project Reference ID to scrape, and the necessary Service role API key</h2>
          <div className={jobStyles.job}>
            <ScrapeIntervalField control={control} />
            <AuthField
              register={register}
              control={control}
              errors={errors}
              watch={watch}
              onSuccess={() => setConnectionSuccess(true)}
            />
          </div>
        </li>
        <JobStatusWidget />
        <Button
          variant="primary"
          type="submit"
          aria-label="Save Scrape Job"
          disabled={!connectionSuccess || createJobStatus === QueryStatus.pending}
          data-testid={Pages.MetricsEndpointIntegration.saveScrapeJobButton}
        >
          {createJobStatus === QueryStatus.pending && <Spinner className={jobStyles.spinner} />}
          Save Scrape Job
        </Button>
      </ol>
    </form>
  );
};
