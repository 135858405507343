import { FeatureToggles } from '@grafana/data';
import { config } from '@grafana/runtime';

import { isGrafanaAtLeast10 } from './misc';

enum ConnectionsFeatureToggle {
  connectionsEnableAlertsToggle = 'connectionsEnableAlertsToggle',
}

type ConnectionsFeatureToggles = FeatureToggles & {
  [key in ConnectionsFeatureToggle]: boolean;
};

function isFeatureToggleEnabled(featureName: ConnectionsFeatureToggle): boolean {
  return (config.featureToggles as ConnectionsFeatureToggles)[featureName];
}

export function isCSPAppEnabled(): boolean {
  return config.apps !== undefined && Object.hasOwn(config.apps, 'grafana-csp-app') && isGrafanaAtLeast10();
}

export function isCollectorAppEnabled(): boolean {
  return config.apps !== undefined && Boolean(config.apps['grafana-collector-app']);
}

export function isAlertsToggleEnabled(): boolean {
  return isFeatureToggleEnabled(ConnectionsFeatureToggle.connectionsEnableAlertsToggle);
}
