import { AnyAction, ThunkDispatch, createAsyncThunk } from '@reduxjs/toolkit';

import { NoDataSourceFoundError, requestDatasource } from 'api/baseApi';
import { doesDashboardExist } from 'api/hostedGrafana/hostedGrafana';
import * as api from 'api/integrations/integrationsApi';
import { handleError } from 'features/app/state/actions';
import { ErrorApiResult, QueryDatasourceResult, isErrorResponse } from 'models/api-models';
import { RootState } from 'state';
import { installSingleIntegration, removeDashboards } from 'state/source/actions';
import { InstallationStatus } from 'state/source/slice';
import { GRAFANA_AGENT_CHECK_ID } from 'utils/consts';
import { text } from 'utils/errors';
import { parseErrorMessage } from 'utils/misc';

import {
  AgentStatus,
  setAgentStatus,
  installedAgentCheck,
  setSaveSelectionsStatus,
  IntegrationConnectionErrorCause,
  IntegrationConnectionStatus,
  setIntegrationConnectionError,
  setIntegrationConnectionNoData,
  setIntegrationConnectionStatus,
  setTracesConnectionStatus,
} from './slice';

export const testAgentConnectionRequest = createAsyncThunk(
  'agent/testAgentConnectionRequest',
  async (integrationId: string, thunkAPI) => {
    thunkAPI.dispatch(setAgentStatus({ agentStatus: AgentStatus.Pending, integrationId }));
    /**
     * queryParam to test if the agent is running
     * agent_build_info
     *
     */
    const queryParam = `agent_build_info`;

    try {
      const result = await api.queryDatasource('grafanacloud-prom', queryParam);
      if (result.length) {
        return thunkAPI.dispatch(setAgentStatus({ agentStatus: AgentStatus.Success, integrationId }));
      } else {
        return thunkAPI.dispatch(setAgentStatus({ agentStatus: AgentStatus.NoData, integrationId }));
      }
    } catch (error) {
      if (error instanceof NoDataSourceFoundError) {
        return thunkAPI.dispatch(handleError(text.errorFunctions.noDataSourceFound(error.message), error));
      } else {
        return thunkAPI.dispatch(handleError(text.error.queryDatasource, error as Error));
      }
    }
  }
);

export const installAgentCheck = createAsyncThunk(
  'agent/installAgentCheck',
  async ({ orgSlug }: { orgSlug: string }, thunkAPI) => {
    const dashboardInstallInfos = await api.getDashboardInstallInfos(GRAFANA_AGENT_CHECK_ID);

    if (dashboardInstallInfos.length > 0) {
      const doesAgentCheckDashboardExist = await doesDashboardExist(dashboardInstallInfos[0].dashboard.uid);

      if (!doesAgentCheckDashboardExist) {
        await thunkAPI.dispatch(installSingleIntegration({ integrationId: GRAFANA_AGENT_CHECK_ID, orgSlug }));
      }
      await thunkAPI.dispatch(installedAgentCheck());
    }
  }
);

export const updateConfiguration = createAsyncThunk(
  'source/updateConfiguration',
  async ({ integrationId, orgSlug }: { integrationId: string; orgSlug: string }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setSaveSelectionsStatus(InstallationStatus.Pending));

      const state = thunkAPI.getState() as RootState;
      const previouslySavedIsLogsEnabled = !Boolean(
        state.source.sourceDetails.installation?.configuration?.configurable_logs?.logs_disabled
      );
      await removeDashboards(integrationId, previouslySavedIsLogsEnabled);

      await thunkAPI.dispatch(installSingleIntegration({ integrationId, orgSlug }));

      thunkAPI.dispatch(setSaveSelectionsStatus(InstallationStatus.Success));
    } catch (error) {
      thunkAPI.dispatch(setSaveSelectionsStatus(InstallationStatus.Error));
      if (isErrorResponse<ErrorApiResult>(error)) {
        const errorObject: Error = { name: error.data.status, message: error.data.error };
        thunkAPI.dispatch(handleError(parseErrorMessage(error, text.error.saveConfigurationSelection), errorObject));
      } else if (error instanceof Error) {
        thunkAPI.dispatch(handleError({ message: error.message }, error));
      }
    }
  }
);

async function isAgentSendingMetrics(query: string | undefined, dispatch: ThunkDispatch<unknown, unknown, AnyAction>) {
  if (!query) {
    dispatch(setIntegrationConnectionError(IntegrationConnectionErrorCause.Unexpected));
    return false;
  }

  const metricsResult = await api.queryDatasource<QueryDatasourceResult>('grafanacloud-prom', query);

  if (!metricsResult || metricsResult.length === 0) {
    dispatch(setIntegrationConnectionNoData(IntegrationConnectionErrorCause.NoMetricsFound));
    return false;
  } else if (metricsResult.every((metric) => metric.value[1] === '0')) {
    dispatch(setIntegrationConnectionNoData(IntegrationConnectionErrorCause.AgentCannotScrapeMetrics));
    return false;
  } else {
    return true;
  }
}

async function isAgentSendingLogs(query: string | undefined, dispatch: ThunkDispatch<unknown, unknown, AnyAction>) {
  if (!query) {
    dispatch(setIntegrationConnectionError(IntegrationConnectionErrorCause.Unexpected));
    return false;
  }

  const logsResult = await api.queryDatasource<QueryDatasourceResult>('grafanacloud-logs', query);

  if (!logsResult || logsResult.length === 0 || logsResult.every((result) => result.value[1] === '0')) {
    dispatch(setIntegrationConnectionNoData(IntegrationConnectionErrorCause.NoLogsFound));
    return false;
  } else {
    return true;
  }
}

export const testIntegrationConnectionRequest = createAsyncThunk(
  'source/testIntegrationConnectionRequest',
  async (integrationId: string, thunkAPI) => {
    thunkAPI.dispatch(setIntegrationConnectionStatus({ status: IntegrationConnectionStatus.Pending, integrationId }));

    const state = thunkAPI.getState() as RootState;
    const sourceDetails = state.source.sourceDetails;
    const { isLogsEnabled } = state.agent.configuredParameters;

    const isIntegrationUsingMetrics = Boolean(sourceDetails.metrics_check_query);
    const isIntegrationUsingLogs =
      (isLogsEnabled === undefined || isLogsEnabled === true) && Boolean(sourceDetails.logs_check_query);

    try {
      let isTestSuccessful = true;
      if (isIntegrationUsingMetrics) {
        isTestSuccessful = await isAgentSendingMetrics(sourceDetails.metrics_check_query, thunkAPI.dispatch);
      }

      if (isTestSuccessful && isIntegrationUsingLogs) {
        isTestSuccessful = await isAgentSendingLogs(sourceDetails.logs_check_query, thunkAPI.dispatch);
      }

      if (isTestSuccessful) {
        thunkAPI.dispatch(
          setIntegrationConnectionStatus({ status: IntegrationConnectionStatus.Success, integrationId })
        );
      }
      return isTestSuccessful;
    } catch (error) {
      if (error instanceof NoDataSourceFoundError) {
        return thunkAPI.dispatch(handleError(text.errorFunctions.noDataSourceFound(error.message), error));
      } else {
        return thunkAPI.dispatch(setIntegrationConnectionError(IntegrationConnectionErrorCause.Unexpected));
      }
    }
  }
);

const TEMPO_UID = 'grafanacloud-traces';
export const testTracesConnection = createAsyncThunk(
  'source/testTracesConnection',
  async ({ serviceName, serviceNamespace }: { serviceName: string; serviceNamespace?: string }, { dispatch }) => {
    try {
      dispatch(
        setTracesConnectionStatus({ status: IntegrationConnectionStatus.Pending, serviceName, serviceNamespace })
      );
      const queryComponents = [`resource.service.name="${serviceName}"`];
      if (serviceNamespace) {
        queryComponents.push(`resource.service.namespace="${serviceNamespace}"`);
      }

      const response = await requestDatasource(
        TEMPO_UID,
        `/api/search?q=${encodeURIComponent(`{${queryComponents.join(' && ')}}`)}&limit=1`
      );

      if (response.status !== 200) {
        dispatch(
          setTracesConnectionStatus({ status: IntegrationConnectionStatus.Error, serviceName, serviceNamespace })
        );
        return;
      }

      const { traces } = response.data;

      if (!(traces && Array.isArray(traces))) {
        dispatch(
          setTracesConnectionStatus({ status: IntegrationConnectionStatus.Error, serviceName, serviceNamespace })
        );
        return;
      }

      if (traces.length > 0) {
        const encodedServiceName = encodeURIComponent(serviceName);
        const linkPrefix = `/a/grafana-app-observability-app/services`;
        const link = `${linkPrefix}/${serviceNamespace ? `${encodeURIComponent(serviceNamespace)}---` : ''}${encodedServiceName}`;

        dispatch(
          setTracesConnectionStatus({
            status: IntegrationConnectionStatus.Success,
            link,
            serviceName,
            serviceNamespace,
          })
        );
      } else {
        dispatch(
          setTracesConnectionStatus({ status: IntegrationConnectionStatus.NoData, serviceName, serviceNamespace })
        );
      }
    } catch {
      dispatch(setTracesConnectionStatus({ status: IntegrationConnectionStatus.Error, serviceName, serviceNamespace }));
    }
  }
);
