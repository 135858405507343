// copied from https://docs.google.com/document/d/1HqT0dCd6iSjF_61qo1NdPAIXO6jXHjIxHsJ5HbPuDRA/edit

export interface OTELCollectorFeatures {
  enableAppO11yHostInfoConnector?: boolean;
}

const createOtelCollectorConfig = (features: OTELCollectorFeatures) => `
extensions:
  basicauth/grafana_cloud:
    # https://github.com/open-telemetry/opentelemetry-collector-contrib/tree/main/extension/basicauthextension
    client_auth:
      username: env("GRAFANA_CLOUD_INSTANCE_ID")
      password: env("GRAFANA_CLOUD_API_KEY")
${
  features.enableAppO11yHostInfoConnector
    ? `
connectors:
  grafanacloud:
    # https://github.com/open-telemetry/opentelemetry-collector-contrib/tree/main/connector/grafanacloudconnector
    host_identifiers: ["host.name"]}
`
    : ''
}
receivers:
  otlp:
    # https://github.com/open-telemetry/opentelemetry-collector/tree/main/receiver/otlpreceiver
    protocols:
      grpc:
      http:
  hostmetrics:
    # Optional. Host Metrics Receiver added as an example of Infra Monitoring capabilities of the OpenTelemetry Collector
    # https://github.com/open-telemetry/opentelemetry-collector-contrib/tree/main/receiver/hostmetricsreceiver
    scrapers:
      load:
      memory:

processors:
  batch:
    # https://github.com/open-telemetry/opentelemetry-collector/tree/main/processor/batchprocessor
  resourcedetection:
    # Enriches telemetry data with resource information from the host
    # https://github.com/open-telemetry/opentelemetry-collector-contrib/tree/main/processor/resourcedetectionprocessor
    detectors: ["env", "system"]
    override: false
  transform/add_resource_attributes_as_metric_attributes:
    # https://github.com/open-telemetry/opentelemetry-collector-contrib/tree/main/processor/transformprocessor
    error_mode: ignore
    metric_statements:
      - context: datapoint
        statements:
          - set(attributes["deployment.environment"], resource.attributes["deployment.environment"])
          - set(attributes["service.version"], resource.attributes["service.version"])

exporters:
  otlphttp/grafana_cloud:
    # https://github.com/open-telemetry/opentelemetry-collector/tree/main/exporter/otlpexporter
    endpoint: env("GRAFANA_CLOUD_OTLP_ENDPOINT")
    auth:
      authenticator: basicauth/grafana_cloud

service:
  extensions: [basicauth/grafana_cloud]
  pipelines:
    traces:
      receivers: [otlp]
      processors: [resourcedetection, batch]
      exporters: [otlphttp/grafana_cloud${features.enableAppO11yHostInfoConnector ? ', grafanacloud' : ''}]
    metrics:
      receivers: [otlp, hostmetrics]
      processors: [resourcedetection, transform/add_resource_attributes_as_metric_attributes, batch]
      exporters: [otlphttp/grafana_cloud]
    logs:
      receivers: [otlp]
      processors: [resourcedetection, batch]
      exporters: [otlphttp/grafana_cloud]
`;

export function getCollectorConfig(
  envVar: { [key in string]: number | string | undefined },
  features: OTELCollectorFeatures
): string {
  let content = createOtelCollectorConfig(features);

  for (const key in envVar) {
    const val = envVar[key];
    content = content.replace(new RegExp(`env\\("${key}"\\)`, 'g'), `"${val}"`);
  }
  return content;
}
