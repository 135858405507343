import { config } from '@grafana/runtime';

import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';
import { isCSPAppEnabled } from 'utils/featureToggles';

import { CICDObservability } from './CICDObservability';
import { awsApp } from './awsApp';
import { azure } from './azure';
import { cspApp } from './cspApp';
import { dotnet } from './dotnet';
import { faro } from './faro';
import { gcp } from './gcp';
import { hostedGraphiteMetrics } from './hostedGraphiteMetrics';
import { hostedLogs } from './hostedLogs';
import { hostedPrometheusMetrics } from './hostedPrometheusMetrics';
import { hostedTraces } from './hostedTraces';
import { httpMetrics } from './httpMetrics';
import { k6 } from './k6';
import { kubernetesMonitoring } from './kubernetesMonitoring';
import { logsOnboarding } from './logsOnboarding';
import { logsHttp } from './logshttp';
import { javaOtel, openTelemetry, openTelemetryCollector } from './openTelemetry';
import { hostedProfiles, pyroscope } from './pyroscope';
import { syntheticMonitoring } from './syntheticMonitoring';

// include Grafana Cloud apps here to include them in the catalog
export const cloudApps: Source[] = [
  ...(isCSPAppEnabled() ? [awsApp, cspApp, azure, gcp] : []),
  syntheticMonitoring,
  kubernetesMonitoring,
  k6,
  pyroscope,
  CICDObservability,
  // This feature toggle is not defined in Grafana OSS.
  ...((config.featureToggles as any)['cloudOnboardingFaroListed'] ? [faro] : []),
];

export const hostedDataIntegrations: Source[] = [
  hostedPrometheusMetrics,
  hostedLogs,
  hostedGraphiteMetrics,
  hostedTraces,
  hostedProfiles,
  httpMetrics,
  logsHttp,
  logsOnboarding,
];

export const localAgentIntegrations: Source[] = [dotnet, openTelemetry, openTelemetryCollector];

export const alphaIntegrations: Source[] = [];

// include local sources here to show them in the catalog on the Java page
export const javaLocalSources: Source[] = [javaOtel];

// aims to include all local sources that we have built instructions for locally
export const sourcesWithLocalInstructions = [
  ...hostedDataIntegrations,
  ...localAgentIntegrations,
  ...javaLocalSources,
].filter((source) => source.type !== SourceType.Alpha);
