import React, { useCallback } from 'react';
import { FieldErrors, UseFormMethods } from 'react-hook-form';

import { Field, Input, useStyles2 } from '@grafana/ui';

import { ConnectionCheckButton } from 'components/SaasIntegration/ConnectionCheckButton';
import { useDispatch } from 'hooks/useDispatch';
import { checkConfluentConnection } from 'state/confluent/actions';
import { FormErrors } from 'utils/consts';
import { ActionType } from 'utils/saasIntegrations';

import { getAddConfluentAPIKeyStyles } from './AddConfluentAPIKey.styles';

interface Props {
  errors: FieldErrors;
  register: UseFormMethods['register'];
  watch: UseFormMethods['watch'];
  onSuccess: () => void;
  actionType: ActionType;
}

const AddConfluentAPIKey = ({ errors, register, watch, onSuccess, actionType }: Props) => {
  const styles = useStyles2(getAddConfluentAPIKeyStyles);
  const state = watch();
  const dispatch = useDispatch();

  const onCheckConnection = useCallback(
    () => () => {
      const { apiKey: api_key, apiSecret: api_secret } = state;
      dispatch(checkConfluentConnection({ payload: { api_key, api_secret } }));
    },
    [dispatch, state]
  );

  return (
    <div className={styles.spacer}>
      <h2>Enter your Confluent Cloud API key</h2>
      <p>
        Enter the information from the Cloud API key you created in Confluent Cloud. For more information on how to
        obtain your API key, please visit
        <a
          target="_blank"
          rel="noreferrer"
          className={styles.link}
          href="https://docs.confluent.io/cloud/current/access-management/authenticate/api-keys/api-keys.html#cloud-cloud-api-keys"
        >
          Confluent API Keys Documentation
        </a>
        .
      </p>
      <Field
        label="API Key"
        description="Paste the API key from Confluent Cloud."
        invalid={errors.apiKey !== undefined}
        error={errors.apiKey ? FormErrors.REQUIRED_FIELD : ''}
      >
        <Input
          placeholder="xxxxxxxxxxxxxxx"
          id="apiKey"
          name="apiKey"
          ref={register({ required: actionType === ActionType.Add })}
        />
      </Field>
      <Field
        label="API Secret"
        description="Paste the API secret from Confluent Cloud."
        invalid={errors.apiSecret !== undefined}
        error={errors.apiSecret ? FormErrors.REQUIRED_FIELD : ''}
      >
        <Input
          type="password"
          placeholder="xxxxxxxxxxxxxxx"
          id="apiSecret"
          name="apiSecret"
          ref={register({ required: actionType === ActionType.Add })}
        />
      </Field>
      <ConnectionCheckButton
        disabled={!state.apiKey || !state.apiSecret}
        onSuccess={() => {
          onSuccess();
        }}
        onCheckConnection={onCheckConnection}
      />
    </div>
  );
};

export default AddConfluentAPIKey;
