import { createAsyncThunk } from '@reduxjs/toolkit';

import { collectorStore, resetSelectedOs } from '@grafana-cloud/collector';
import { requestIntegrations } from 'api/baseApi';
import { hostedExportersApi } from 'api/hostedExporters/hostedExportersApi';
import { createDashboard, createFolder, getFolderUid, removeFolder } from 'api/hostedGrafana/hostedGrafana';
import * as api from 'api/integrations/integrationsApi';
import { integrationsApi } from 'api/integrations/integrationsRtqApi';
import { handleError, handleLocalError } from 'features/app/state/actions';
import { unsetGeneralError } from 'features/app/state/slice';
import {
  ErrorApiResult,
  ScrapeJobApiResponse,
  SuccessfulApiResult,
  VersionHistory,
  isErrorResponse,
} from 'models/api-models';
import { RootState } from 'state';
import { text } from 'utils/errors';
import { parseErrorMessage } from 'utils/misc';

import {
  InstallationStatus,
  SourceDetails,
  openSourcePage,
  setIntegrationInstallationStatus,
  setIntegrationUpdateStatus,
  setSourceDetails,
  setSourceVersionHistory,
  setFolderUIDs,
} from './slice';

async function installDashboards(integrationId: string, isLogsEnabled?: boolean) {
  const dashboardInstallInfos = await api.getDashboardInstallInfos(integrationId, isLogsEnabled);
  const uniqueFolderNames = new Set(
    dashboardInstallInfos.map((dashboardInstallInfo) => dashboardInstallInfo.folder_name)
  );

  const createFolderPromises = Array.from(uniqueFolderNames.values()).map((folderName) => createFolder(folderName));
  await Promise.all(createFolderPromises);

  for (const dashboardInstallInfo of dashboardInstallInfos) {
    await createDashboard(
      dashboardInstallInfo.dashboard,
      dashboardInstallInfo.folder_name,
      dashboardInstallInfo.overwrite
    );
  }
}

export const installSingleIntegration = createAsyncThunk(
  'source/installSingleIntegration',
  async ({ integrationId, orgSlug }: { integrationId: string; orgSlug: string }, thunkAPI) => {
    thunkAPI.dispatch(setIntegrationInstallationStatus({ status: InstallationStatus.Pending, integrationId }));

    const state = thunkAPI.getState() as RootState;
    const isLogsEnabled: boolean | undefined = state.agent.configuredParameters.isLogsEnabled;
    const isAlertsEnabled: boolean | undefined = state.agent.configuredParameters.isAlertsEnabled;

    try {
      await installDashboards(integrationId, isLogsEnabled);
      await api.installIntegration(integrationId, isLogsEnabled, isAlertsEnabled);

      thunkAPI.dispatch(integrationsApi.util.invalidateTags(['integrations']));

      // update sourceDetails from the API, because it should have new saved values
      const response = await api.getIntegrationDetails(integrationId);
      thunkAPI.dispatch(setSourceDetails(response));

      thunkAPI.dispatch(unsetGeneralError());
      thunkAPI.dispatch(setIntegrationInstallationStatus({ status: InstallationStatus.Success, integrationId }));
    } catch (error) {
      thunkAPI.dispatch(setIntegrationInstallationStatus({ status: InstallationStatus.Error, integrationId }));
      if (isErrorResponse<ErrorApiResult>(error)) {
        const errorObject: Error = { name: error.data.status, message: error.data.error };
        thunkAPI.dispatch(handleError(parseErrorMessage(error, text.error.installIntegration, orgSlug), errorObject));
      } else if (error instanceof Error) {
        thunkAPI.dispatch(handleError({ message: error.message }, error));
      }

      try {
        await removeDashboards(integrationId, isLogsEnabled);
      } catch (e) {
        if (isErrorResponse<ErrorApiResult>(e)) {
          const errorObject: Error = { name: e.data.status, message: e.data.error };
          thunkAPI.dispatch(
            handleError(
              parseErrorMessage(e, text.errorFunctions.installRollbackFailed(integrationId), orgSlug),
              errorObject
            )
          );
        } else if (e instanceof Error) {
          thunkAPI.dispatch(handleError({ message: e.message }, e));
        }
      }
    }
  }
);

export async function removeDashboards(integrationId: string, isLogsEnabled?: boolean): Promise<void[]> {
  const dashboardInstallInfos = await api.getDashboardInstallInfos(integrationId, isLogsEnabled);

  const uniqueFolderNames = new Set(
    dashboardInstallInfos.map((dashboardInstallInfo) => dashboardInstallInfo.folder_name)
  );

  const removeFolderPromises = Array.from(uniqueFolderNames.values()).map((folderName) => removeFolder(folderName));

  return Promise.all(removeFolderPromises);
}

export const uninstallIntegration = createAsyncThunk(
  'source/uninstallIntegration',
  async ({ integrationId }: { integrationId: string }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const isLogsEnabled: boolean | undefined = state.agent.configuredParameters.isLogsEnabled;

    try {
      await removeDashboards(integrationId, isLogsEnabled);
      await api.uninstallIntegration(integrationId);

      thunkAPI.dispatch(integrationsApi.util.invalidateTags(['integrations']));
      thunkAPI.dispatch(unsetGeneralError());
    } catch (error) {
      if (isErrorResponse<ErrorApiResult>(error)) {
        const errorObject: Error = { name: error.data.status, message: error.data.error };
        thunkAPI.dispatch(handleError(parseErrorMessage(error, text.error.uninstallIntegration), errorObject));
      } else if (error instanceof Error) {
        thunkAPI.dispatch(handleError({ message: error.message }, error));
      }
    }
  }
);

export const removeAllScrapeJobs = createAsyncThunk(
  'source/removeAllScrapeJobs',
  async ({ jobNames }: { jobNames: string[] }, thunkAPI) => {
    try {
      await Promise.all(
        jobNames.map(async (jobName) => {
          const options = {
            method: 'DELETE',
          };

          return requestIntegrations<SuccessfulApiResult<ScrapeJobApiResponse[]>>(`/he-api/job/${jobName}`, options);
        })
      );
      thunkAPI.dispatch(hostedExportersApi.util.invalidateTags(['jobs']));
    } catch (error) {
      if (isErrorResponse<ErrorApiResult>(error)) {
        const errorObject: Error = { name: error.data.status, message: error.data.error };
        thunkAPI.dispatch(handleError(parseErrorMessage(error, text.error.uninstallIntegration), errorObject));
      } else if (error instanceof Error) {
        thunkAPI.dispatch(handleError({ message: error.message }, error));
      }
    }
  }
);

export const fetchIntegrationDetails = createAsyncThunk(
  'source/fetchIntegrationDetails',
  async ({ integrationId }: { integrationId: string }, thunkAPI) => {
    try {
      const sourceDetails: SourceDetails = await api.getIntegrationDetails(integrationId);
      thunkAPI.dispatch(setSourceDetails(sourceDetails));
    } catch (error) {
      if (isErrorResponse<ErrorApiResult>(error)) {
        const errorObject: Error = { name: error.data.status, message: error.data.error };
        if (error.status === 404) {
          thunkAPI.dispatch(handleError(parseErrorMessage(error, text.error.integrationNotFound), errorObject));
        } else {
          thunkAPI.dispatch(handleError(parseErrorMessage(error, text.error.queryDetails), errorObject));
        }
      } else if (error instanceof Error) {
        thunkAPI.dispatch(handleError({ message: error.message }, error));
      }
    }
  }
);

export const fetchVersionHistory = createAsyncThunk(
  'source/versionHistory',
  async ({ integrationId }: { integrationId: string }, thunkAPI) => {
    try {
      const versionHistory: VersionHistory[] = await api.getIntegrationChangelog(integrationId);
      thunkAPI.dispatch(setSourceVersionHistory(versionHistory));
    } catch (error) {
      if (isErrorResponse<ErrorApiResult>(error)) {
        const errorObject: Error = { name: error.data.status, message: error.data.error };
        thunkAPI.dispatch(handleError(parseErrorMessage(error, text.error.queryDetails), errorObject));
      } else if (error instanceof Error) {
        thunkAPI.dispatch(handleError({ message: error.message }, error));
      }
    }
  }
);

export const updateIntegration = createAsyncThunk(
  'source/updateIntegration',
  async ({ integrationId }: { integrationId: string }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIntegrationUpdateStatus({ status: InstallationStatus.Pending, integrationId }));

      const state = thunkAPI.getState() as RootState;
      const isLogsEnabled: boolean | undefined = state.agent.configuredParameters.isLogsEnabled;

      await removeDashboards(integrationId, isLogsEnabled);
      await installDashboards(integrationId, isLogsEnabled);
      await api.upgradeIntegration(integrationId);
      const response = await api.getIntegrationDetails(integrationId);

      thunkAPI.dispatch(integrationsApi.util.invalidateTags(['integrations']));
      thunkAPI.dispatch(setSourceDetails(response));
      thunkAPI.dispatch(unsetGeneralError());
      thunkAPI.dispatch(setIntegrationUpdateStatus({ status: InstallationStatus.Success, integrationId }));
    } catch (error) {
      thunkAPI.dispatch(setIntegrationUpdateStatus({ status: InstallationStatus.Error, integrationId }));
      if (isErrorResponse<ErrorApiResult>(error)) {
        const errorObject: Error = { name: error.data.status, message: error.data.error };
        thunkAPI.dispatch(handleLocalError(parseErrorMessage(error, text.error.updateIntegration), errorObject));
      } else if (error instanceof Error) {
        thunkAPI.dispatch(handleLocalError({ message: error.message }, error));
      }
    }
  }
);

export const openSourcePageThunk = createAsyncThunk(
  'source/openSourcePageThunk',
  async ({ integrationId }: { integrationId: string }, thunkAPI) => {
    thunkAPI.dispatch(openSourcePage(integrationId));
    collectorStore.dispatch(resetSelectedOs({ integrationId }));
  }
);

export const fetchFolderUIDs = createAsyncThunk('source/fetchFolderUIDs', async (integrationId: string, thunkAPI) => {
  try {
    const dashboardInstallInfos = await api.getDashboardInstallInfos(integrationId);

    const uniqueFolderNames = new Set(dashboardInstallInfos.map((info) => info.folder_name));

    thunkAPI.dispatch(setFolderUIDs(Array.from(uniqueFolderNames).map(getFolderUid)));

    thunkAPI.dispatch(setIntegrationUpdateStatus({ status: InstallationStatus.Success, integrationId }));
  } catch (error) {
    thunkAPI.dispatch(setIntegrationUpdateStatus({ status: InstallationStatus.Error, integrationId }));

    if (isErrorResponse<ErrorApiResult>(error)) {
      const errorObject: Error = { name: error.data.status, message: error.data.error };
      thunkAPI.dispatch(handleLocalError(parseErrorMessage(error, text.error.fetchFolderUIDs), errorObject));
    } else if (error instanceof Error) {
      thunkAPI.dispatch(handleLocalError({ message: error.message }, error));
    }
  }
});
