import { PlanType, Tiers } from './enums';

export const colors = {
  black: '#000000',
  blue03: '#538ade',
  blue04: '#343b40',
  blue07: '#245baf',
  blue09: '#6898e2',
  blue10: '#3274d9',
  blue11: '#6e9fff',
  blue12: 'rgba(50, 116, 217, .15)',
  green01: '#99d98d',
  green02: '#5aa64b',
  red01: '#ff7389',
  red02: '#de314d',
  dark02: '#111217',
  dark03: '#424345',
  labelTextColor01: '#ccccdc',

  yellow01: '#bb9205',
};

export const CHECK_TIMEOUT_MS = 60 * 1000;
export const CHECK_INTERVAL_MS = 5 * 1000;

export const AGENT_FAQ_LINK = 'https://grafana.com/docs/agent/latest/';

// maybe this, too?
export const errorIntegrationApiResponse = {
  dashboard: 'unable to import dashboards: dashboard quota reached',
  alert: 'unable to apply alerting rules: could not create rule group: rule groups per tenant limit reached',
  record: 'unable to apply recording rules: could not create rule group: rule groups per tenant limit reached',
};

export const GRAFANA_EXAMPLE_USER = '<grafana.com username>';
export const GRAFANA_EXAMPLE_API = '<grafana.com API Key>';
export const DEFAULT_PROM_URL = 'https://prometheus-us-central1.grafana.net/api/prom/push';
export const DEFAULT_LOKI_URL = 'logs-prod-us-central1.grafana.net/api/prom/push';
export const DEFAULT_GRAPHITE_URL = 'https://graphite-us-central1.grafana.net/metrics';
export const DEFAULT_TEMPO_URL = 'tempo-us-central1.grafana.net';
export const DEFAULT_PYROSCOPE_URL = 'profiles-prod-001.grafana.net';

export enum FormErrors {
  REQUIRED_FIELD = 'This field is required',
  JOB_NAME_CHARACTERS = 'Scrape job name can only contain alphanumeric characters, dashes, and underscores.',
  SCRAPE_JOB_NAME_EXISTS = 'A scrape job with this name already exists. Please choose a unique name.',
}

export const SCROLL_CONTAINER_SELECTOR = '.scrollbar-view';

export const DISABLED_REASONS = {
  disabled_by_user: {
    title: 'User disabled access',
    description: 'This scrape job was disabled by the user.',
  },
  credentials_revoked: {
    title: 'Credentials revoked',
    description: 'This scrape job was disabled because credentials have been deleted.',
  },
  over_series_limit: {
    title: 'Over series limit',
    description: 'This scrape job was disabled because the series limit has been reached.',
  },
};

export type DisabledReasonType = keyof typeof DISABLED_REASONS;

export const DEFAULT_SCRAPE_INTERVAL = '15s';

export const CREATE_OR_UPDATE_JOBS_CACHE_KEY = 'create-or-update-jobs';

export const GRAFANA_AGENT_ID = 'grafana-agent';
export const SELF_HOSTED_MIMIR_ID = 'mimir';
export const GRAFANA_AGENT_CHECK_ID = 'grafana-agent-check';
export const LINUX_NODE_ID = 'linux-node';
export const RASPBERRY_PI_NODE_ID = 'raspberry-pi-node';
export const CILIUM_ENTERPRISE_ID = 'cilium-enterprise';
export const ISTIO_ID = 'istio';
export const VELERO_ID = 'velero';
export const JAVA_ID = 'java';

export const DOCKER_DESKTOP_ID = 'docker-desktop';

export const ORG_INFO = 'grafanacloud_org_info';
export const PRO_PLAN_NAME = 'Pro plan';
const FREE_PLAN_NAME = 'Free plan';
const TRIAL_PLAN_NAME = 'Pro trial';

export const planNames = {
  [Tiers.Free]: FREE_PLAN_NAME,
  [Tiers.Trial]: TRIAL_PLAN_NAME,
  [Tiers.Pro]: PRO_PLAN_NAME,
  [Tiers.GCPPro]: PRO_PLAN_NAME,
  [Tiers.GCPFlatFeePro]: PRO_PLAN_NAME,
  [Tiers.LegacyPro]: PRO_PLAN_NAME,
  [Tiers.AzurePro]: PRO_PLAN_NAME,
};

export const HOSTED_DATA_DETAILS_FALLBACK = {
  orgSlug: '',
  orgId: 0,
  slug: '',
  id: 0,
  trial: 0,
  hmInstancePromId: 0,
  hlInstanceId: 0,
  hmInstanceGraphiteId: 0,
  htInstanceId: 0,
  hpInstanceId: 0,
  plan: PlanType.Gcloud,
  trialExpiresAt: '',
  hlInstanceUrl: '',
  hmInstanceGraphiteUrl: '',
  hmInstancePromUrl: '',
  htInstanceUrl: '',
  hpInstanceUrl: '',
  hmInstanceGraphiteType: '',
  regionSlug: '',
  url: 'https://example.grafana.net',
};

export const HOSTNAME_RELABEL_KEY = '<your-instance-name>';
export const ALLOY_HOSTNAME_RELABEL_KEY = 'constants.hostname';

export const INSTALL_TROUBLESHOOTING_DOCS_LINK =
  'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/troubleshoot/install-troubleshoot';
