import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Button, Field, Spinner, useStyles2 } from '@grafana/ui';

import { useCreateOrUpdateScrapeJobMutation } from 'api/hostedExporters/hostedExportersApi';
import { ConfluentJob, ConfluentJobApiResponse, Resource } from 'models/api-models';
import { RootState } from 'state';
import { CREATE_OR_UPDATE_JOBS_CACHE_KEY } from 'utils/consts';
import { ConnectionStatus } from 'utils/enums';
import { ActionType, getReadableConfluentJob, transformResources } from 'utils/saasIntegrations';

import { EditJobFormProps } from '../JobManager/JobManager';
import { JobStatusWidget } from '../JobManager/JobStatusWidget';

import AddConfluentAPIKey from './AddConfluentAPIKey';
import { AddResources } from './AddResources';
import { getJobFormStyles } from './ConfluentInstructionsStyles';

type ReactHookFormProps = {
  resources: Resource[];
  apiKey: string;
  apiSecret: string;
  name: string;
};

const getDefaultValues = (job: ConfluentJob) => {
  if (typeof job === 'undefined') {
    return {
      apiKey: '',
      apiSecret: '',
      name: '',
      resources: [{ resourceType: '', resourceId: '' }],
    };
  }

  const { name, apiKey, apiSecret, resources } = job;

  const defaultValues = {
    apiKey,
    apiSecret,
    name: name,
    resources: [{ resourceType: '', resourceId: '' }],
  };

  if (Array.isArray(resources) && resources.length) {
    defaultValues.resources = resources;
  }

  return defaultValues;
};

export const EditJob: FC<EditJobFormProps<ConfluentJobApiResponse>> = ({ onSaveJob, job: jobApi }) => {
  const job = getReadableConfluentJob(jobApi);

  const styles = useStyles2(getJobFormStyles);
  const { connectionStatus } = useSelector((state: RootState) => state.saasIntegrations);
  const [_, { status: createJobStatus }] = useCreateOrUpdateScrapeJobMutation({
    fixedCacheKey: CREATE_OR_UPDATE_JOBS_CACHE_KEY,
  });
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<ReactHookFormProps>({
    mode: 'onChange',
    defaultValues: getDefaultValues(job),
  });
  const [connectionSuccess, setConnectionSuccess] = useState(false);

  const state = watch();

  const isSaveScrapeJobButtonDisabled =
    connectionStatus === ConnectionStatus.Error ||
    connectionStatus === ConnectionStatus.Pending ||
    ((state.apiKey !== '' || state.apiSecret !== '') && connectionStatus !== ConnectionStatus.Success) ||
    createJobStatus === QueryStatus.pending;

  const handleEdit = handleSubmit((currentJob: ConfluentJob) => {
    const confluentApiJob = currentJob.resources.reduce(transformResources, {} as { [key: string]: string[] });

    const { apiKey: api_key, apiSecret: api_secret } = state;

    onSaveJob({
      name: job.name || currentJob.name,
      api_key: {
        name: connectionSuccess ? api_key : currentJob.apiKey,
        secret: connectionSuccess ? api_secret : currentJob.apiSecret,
      },
      enabled: job.enabled ?? true,
      ...confluentApiJob,
    });
  });

  return (
    <form onSubmit={handleEdit}>
      <div className={styles.job}>
        <Field label="Scrape job name" invalid={errors.name !== undefined} error={errors.name?.message}>
          <span>{job.name}</span>
        </Field>
        <AddConfluentAPIKey
          actionType={ActionType.Edit}
          errors={errors}
          register={register}
          watch={watch}
          onSuccess={() => setConnectionSuccess(true)}
        />
        <AddResources control={control} errors={errors} register={register} />

        <JobStatusWidget />
        <Button variant="primary" type="submit" aria-label="Save Scrape job" disabled={isSaveScrapeJobButtonDisabled}>
          {createJobStatus === QueryStatus.pending && <Spinner className={styles.spinner} />}
          Save Scrape Job
        </Button>
      </div>
    </form>
  );
};
