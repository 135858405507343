import { LokiLogo } from 'img/hostedData';
import { Source } from 'types/Source';
import { SourceType } from 'utils/enums';

export const LOGS_ONBOARDING_ID = 'logs-onboarding-experiment';

export const logsOnboarding: Source = {
  id: LOGS_ONBOARDING_ID,
  name: 'Logs Onboarding',
  logo_url: LokiLogo,
  overview: ``,
  type: SourceType.HostedData,
  externalUrl: '/a/cloud-home-app/onboarding-flow/logs-onboarding',
  search_keywords: [
    'alloy',
    'azure',
    'cloudflare',
    'cloudwatch',
    'datadog',
    'docker',
    'elasticsearch',
    'firehose',
    'fluentbit',
    'fluentd',
    'gcp',
    'heroku',
    'http logs',
    'kafka',
    'kubernetes',
    'linux',
    'loki',
    'new relic',
    'opentelementry',
    'oracle',
    'splunk',
    'sumo logic',
    'windows',
  ],
  categories: ['Logs'],
};
