import { css } from '@emotion/css';
import React, { useCallback } from 'react';
import { FieldErrors, UseFormMethods } from 'react-hook-form';

import { Field, Input } from '@grafana/ui';

import { ConnectionCheckButton } from 'components/SaasIntegration/ConnectionCheckButton';
import { useDispatch } from 'hooks/useDispatch';
import { MetricsEndpointFlavor } from 'models/api-models';
import { checkMetricsEndpointConnection } from 'state/metricsEndpoint/actions';

const getAuthFieldStyles = () => ({
  descriptionWithCodeBlock: css({
    // the height of a <code> element is 23px
    lineHeight: '23px',
  }),
});

interface Props {
  errors: FieldErrors;
  register: UseFormMethods['register'];
  watch: UseFormMethods['watch'];
  onSuccess: () => void;
  control: UseFormMethods['control'];
}

const AuthField = ({ errors, register, watch, onSuccess, control }: Props) => {
  const state = watch();
  const dispatch = useDispatch();
  const styles = getAuthFieldStyles();

  const onCheckConnection = useCallback(
    () => () => {
      const { apiKey: api_key, projectId: project_id } = state;
      dispatch(
        checkMetricsEndpointConnection({
          payload: { supabase: { api_key, project_id }, flavor: MetricsEndpointFlavor.Supabase },
          mainErrorField: project_id,
          urlField: 'Project Reference ID',
        })
      );
    },
    [dispatch, state]
  );

  return (
    <>
      <Field
        label="Project Reference ID"
        invalid={errors.projectId !== undefined}
        error="Valid project reference ID is required"
        description={
          <div className={styles.descriptionWithCodeBlock}>
            Your project reference ID can also be found in your project URL, such as{' '}
            <code>https://&lt;project-reference-id&gt;.supabase.co</code>
          </div>
        }
      >
        <Input
          type="text"
          aria-label="project reference ID"
          id="project-reference-id"
          name="projectId"
          placeholder="Project Reference ID"
          ref={register({
            required: true,
          })}
        />
      </Field>
      <Field
        label="Service role API Key"
        invalid={errors.apiKey !== undefined}
        error="Valid API key is required"
        description={
          <div className={styles.descriptionWithCodeBlock}>
            The <code>service_role</code> key is needed to scrape metrics from Supabase
          </div>
        }
      >
        <Input
          type="password"
          aria-label="service role api key"
          id="service-role-api-key"
          name="apiKey"
          placeholder="Service role API Key"
          ref={register({
            required: true,
          })}
        />
      </Field>
      <ConnectionCheckButton
        onSuccess={() => onSuccess()}
        disabled={!state.projectId || !state.apiKey}
        onCheckConnection={onCheckConnection}
        successMessage="All good! Successfully scraped metrics from URL"
      />
    </>
  );
};

export default AuthField;
