import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Spinner, useStyles2 } from '@grafana/ui';

import { useCreateOrUpdateScrapeJobMutation } from 'api/hostedExporters/hostedExportersApi';
import { ConfluentJob, Resource } from 'models/api-models';
import { CREATE_OR_UPDATE_JOBS_CACHE_KEY } from 'utils/consts';
import { ActionType, transformResources } from 'utils/saasIntegrations';

import { CreateJobFormProps } from '../JobManager/JobManager';
import { JobStatusWidget } from '../JobManager/JobStatusWidget';
import { ScrapeJobNameField } from '../common/ScrapeJobNameField';

import AddConfluentAPIKey from './AddConfluentAPIKey';
import { AddResources } from './AddResources';
import { getJobFormStyles } from './ConfluentInstructionsStyles';
import { InstructionsComponent } from './InstructionsComponent';

type ReactHookFormProps = {
  resources: Resource[];
  apiKey: string;
  apiSecret: string;
  name: string;
};

export const CreateJob: FC<CreateJobFormProps> = ({ onSaveJob }) => {
  const styles = useStyles2(getJobFormStyles);
  const [connectionSuccess, setConnectionSuccess] = useState(false);
  const [_, { status: createJobStatus }] = useCreateOrUpdateScrapeJobMutation({
    fixedCacheKey: CREATE_OR_UPDATE_JOBS_CACHE_KEY,
  });
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<ReactHookFormProps>({
    mode: 'onChange',
    defaultValues: {
      apiKey: '',
      apiSecret: '',
      name: '',
      resources: [{ resourceType: '', resourceId: '' }],
    },
  });

  const handleCreate = handleSubmit((currentJob: ConfluentJob) => {
    const confluentApiJob = currentJob.resources.reduce(transformResources, {} as { [key: string]: string[] });

    onSaveJob({
      name: currentJob.name,
      api_key: {
        name: currentJob.apiKey,
        secret: currentJob.apiSecret,
      },
      enabled: true,
      ...confluentApiJob,
    });
  });

  return (
    <>
      <InstructionsComponent />
      <form onSubmit={handleCreate}>
        <div className={styles.job}>
          <ScrapeJobNameField register={register} errors={errors} />
          <AddConfluentAPIKey
            actionType={ActionType.Add}
            errors={errors}
            register={register}
            watch={watch}
            onSuccess={() => setConnectionSuccess(true)}
          />
          <AddResources
            isAppendButtonEnabled={connectionSuccess}
            control={control}
            errors={errors}
            register={register}
          />

          <JobStatusWidget />
          <Button
            variant="primary"
            type="submit"
            aria-label="Save Scrape job"
            disabled={!connectionSuccess || createJobStatus === QueryStatus.pending}
          >
            {createJobStatus === QueryStatus.pending && <Spinner className={styles.spinner} />}
            Save Scrape Job
          </Button>
        </div>
      </form>
    </>
  );
};
