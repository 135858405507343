import { css } from '@emotion/css';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, LinkButton } from '@grafana/ui';

import { useSelectedAgentOrSaasIntegration } from 'api/integrations/hooks';
import { Install } from 'components/Install';
import useRudderStack from 'hooks/useRudderstack';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { RootState } from 'state';
import { InstallationStatus } from 'state/source/slice';
import { RudderStackEvents } from 'utils/enums';
import { constructDashboardUrl } from 'utils/misc';

const SuccessConnection: FC = () => {
  const getStyles = (theme: GrafanaTheme2) => ({
    link: css`
      span {
        color: #fff;
      }
      margin-right: ${theme.spacing(1)};
    `,
  });

  const styles = useStyles2(getStyles);
  const selectedIntegration = useSelectedAgentOrSaasIntegration();
  const integrationId = useSelectedIntegrationId();
  const { trackRudderStackEvent } = useRudderStack();

  const onViewDashboardsClick = () => {
    trackRudderStackEvent(RudderStackEvents.ViewDashboards, {
      integration_slug: integrationId,
    });
  };

  return (
    <>
      {selectedIntegration.dashboard_folder && (
        <LinkButton
          className={styles.link}
          onClick={onViewDashboardsClick}
          href={constructDashboardUrl(selectedIntegration.dashboard_folder)}
        >
          View Dashboards
        </LinkButton>
      )}
    </>
  );
};

export const InstallSaasIntegration: FC<{ buttonText?: string }> = ({ buttonText }) => {
  const { integrationInstallationStatus } = useSelector((state: RootState) => state.source);

  return (
    <>
      <Install buttonText={buttonText} />
      {integrationInstallationStatus === InstallationStatus.Success && <SuccessConnection />}
    </>
  );
};
