import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

import { colors } from 'utils/consts';

export const getStyles = (theme: GrafanaTheme2) => ({
  error: css`
    color: ${theme.isLight ? colors.red02 : colors.red01};
  `,
  alert: css`
    margin-bottom: ${theme.spacing(2)};
    display: flex;
    font-size: ${theme.typography.bodySmall.fontSize};
    align-items: center;
  `,
  success: css`
    color: ${theme.isLight ? colors.green02 : colors.green01};
  `,
  warning: css`
    color: ${theme.colors.warning.text};
  `,
  icon: css`
    margin-right: 6px;
  `,
  alertContent: css`
    margin-bottom: 0;
    p {
      margin-bottom: 0;
    }
  `,
  base: css`
    font-size: ${theme.typography.body.fontSize};
  `,
});
