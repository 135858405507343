import findKey from 'lodash/findKey';
import semver from 'semver';

import { config } from '@grafana/runtime';

import { AlertDetails, ErrorApiResult, ErrorFetchResponse, Metrics, RuleDetails } from 'models/api-models';
import { Source } from 'types/Source';

import { ALLOY_HOSTNAME_RELABEL_KEY, errorIntegrationApiResponse, HOSTNAME_RELABEL_KEY } from './consts';
import { SourceType } from './enums';
import { AppError, constructErrorMessage, text } from './errors';

export function parseErrorMessage(
  { status, data: { error } }: ErrorFetchResponse<ErrorApiResult>,
  customErrorMessage: AppError,
  orgSlug?: string
): AppError {
  if (status === 401) {
    return text.error.unauthorizedError;
  }

  if (status !== 403) {
    return customErrorMessage;
  }

  const key = findKey(errorIntegrationApiResponse, (value) => value === error);

  if (key) {
    return constructErrorMessage(orgSlug).error[key];
  }

  return text.error.genericError;
}

export function constructDashboardUrl(folderName: string) {
  folderName = folderName.toLowerCase().replace(/ /g, '-');
  const folder = folderName.replace(/integration---/, 'integration-');
  return `/dashboards/f/${folderName}/${folder}`;
}

export function constructAlertUrl(sourceDetails: { alerts?: AlertDetails; rules?: RuleDetails }) {
  let alertURL = '';
  const { alerts, rules } = sourceDetails;
  if (alerts?.available_alerts && alerts.available_alerts?.length > 0) {
    alertURL = `/alerting/list?search=namespace:${alerts.available_alerts[0].namespace}`;
  } else if (rules?.available_rules && rules.available_rules?.length > 0) {
    alertURL = `/alerting/list?search=namespace:${rules.available_rules[0].namespace}`;
  }
  return alertURL;
}

export function jsonStringRepl<A>(obj: A, searchValue: RegExp, replaceValue: string): A {
  return JSON.parse(JSON.stringify(obj).replace(searchValue, replaceValue));
}

export const isAgentOrSaasIntegration = (selectedSource: Source) =>
  selectedSource.type === SourceType.AgentIntegration ||
  selectedSource.type === SourceType.SaasIntegration ||
  selectedSource.type === SourceType.MetricsEndpointIntegration;

export const replaceHostnameInConfig = (config: string, newValue: string, wrapInQuotes = false) => {
  if (wrapInQuotes) {
    return config.replaceAll(HOSTNAME_RELABEL_KEY, `"${newValue}"`);
  } else {
    return config.replaceAll(HOSTNAME_RELABEL_KEY, newValue);
  }
};

export const replaceAlloyHostnameInConfig = (config: string, newValue: string | undefined) => {
  // constants.hostname should not be wrapped in quotes, but everything else should
  if (newValue) {
    return config.replaceAll(ALLOY_HOSTNAME_RELABEL_KEY, `"${newValue}"`);
  } else {
    return config;
  }
};

export function getHighlightLinesForSnippet(snippet: string, keyword: string): number[] {
  let codeFirstLineIndex = -1;
  return snippet.split('\n').reduce((accumulated, element, index) => {
    if (element.includes('```')) {
      codeFirstLineIndex = index;
    }
    if (element.includes(keyword)) {
      accumulated.push(index - codeFirstLineIndex - 1);
    }
    return accumulated;
  }, [] as number[]);
}

export const replaceKeyInAgentConfig = (config: string, placeholder: string, value: string) => {
  if (value !== '') {
    return config.replace(`"${placeholder}"`, `"${value}"`);
  }
  return config;
};

export const getInfluxUrl = (promUrl: string) => {
  // based on https://grafana.com/docs/grafana-cloud/data-configuration/metrics/metrics-influxdb/push-from-telegraf/
  if (promUrl.includes('prometheus-us-central1.grafana.net')) {
    return 'https://influx-prod-06-prod-us-central-0.grafana.net/api/v1/push/influx/write';
  } else {
    return promUrl.replace('/api/prom/push', '/api/v1/push/influx/write').replace('prometheus', 'influx');
  }
};

export const sortMetricsByName = (metrics: Metrics[]) => {
  return [...metrics].sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
};

export function isGrafanaAtLeast10() {
  // 10.0.0-cloud1 is a prerelease of 10.0.0, and therefore smaller than 10.0.0,
  // but we want to return true for it.
  // The largest minor release in Grafana 9 was 9.5.
  return semver.gt(config.buildInfo.version, '9.9.0');
}

export function getCommonElements(elements: string[][]) {
  return elements.reduce((a, b) => a.filter((c) => b.includes(c)));
}

export const validateURL = (url: string) => {
  try {
    const urlObject = new URL(url);
    return urlObject.protocol === 'https:' ? true : 'URL is not https';
  } catch (err: any) {
    return 'Please provide a valid https URL';
  }
};

export const CONNECT_DATA_URL = isGrafanaAtLeast10() ? '/connections/add-new-connection' : '/connections/connect-data';
export const INTEGRATIONS_URL = isGrafanaAtLeast10()
  ? '/connections/infrastructure'
  : '/connections/your-connections/infrastructure';
export const PLUGIN_URLS = [CONNECT_DATA_URL, INTEGRATIONS_URL];

export const DATASOURCES_URL = isGrafanaAtLeast10()
  ? '/connections/datasources'
  : '/connections/your-connections/datasources';

export function getDatasourceUrl(datasourceId: string) {
  return `/connections/datasources/${datasourceId}/`;
}
