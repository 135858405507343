import { css } from '@emotion/css';
import React, { useCallback } from 'react';
import { Controller, FieldErrors, UseFormMethods } from 'react-hook-form';

import { GrafanaTheme2 } from '@grafana/data';
import { Field, Input, RadioButtonGroup, useStyles2 } from '@grafana/ui';

import { ConnectionCheckButton } from 'components/SaasIntegration/ConnectionCheckButton';
import { Pages } from 'e2eSelectors/pages';
import { useDispatch } from 'hooks/useDispatch';
import { MetricsEndpointFlavor } from 'models/api-models';
import { checkMetricsEndpointConnection } from 'state/metricsEndpoint/actions';

interface Props {
  errors: FieldErrors;
  register: UseFormMethods['register'];
  watch: UseFormMethods['watch'];
  onSuccess: () => void;
  control: UseFormMethods['control'];
}

const getStyles = (theme: GrafanaTheme2) => ({
  radio: css`
    margin-bottom: 10px;
  `,
});

const authOptions = [
  {
    label: 'Basic',
    value: 'basic',
  },
  {
    label: 'Bearer',
    value: 'bearer',
  },
];

const AuthField = ({ errors, register, watch, onSuccess, control }: Props) => {
  const styles = useStyles2(getStyles);
  const state = watch();
  const dispatch = useDispatch();

  const onCheckConnection = useCallback(
    () => () => {
      const {
        basicPassword: basic_password,
        basicUsername: basic_username,
        url: url,
        bearerToken: bearer_token,
        authenticationMethod: authentication_method,
      } = state;
      dispatch(
        checkMetricsEndpointConnection({
          payload: {
            basic_password,
            basic_username,
            url,
            bearer_token,
            authentication_method,
            flavor: MetricsEndpointFlavor.Default,
          },
          mainErrorField: authentication_method,
        })
      );
    },
    [dispatch, state]
  );

  return (
    <>
      <Field label="Type of Authentication Credentials">
        <Controller
          name="authenticationMethod"
          control={control}
          render={(param) => (
            <RadioButtonGroup
              {...param}
              className={styles.radio}
              options={authOptions}
              value={state.authenticationMethod}
            />
          )}
        />
      </Field>
      {state.authenticationMethod === 'basic' ? (
        <>
          <Field
            label="Basic Username"
            invalid={errors.basicUsername !== undefined}
            error="Valid basic username is required"
          >
            <Input
              type="text"
              aria-label="username"
              id="username"
              name="basicUsername"
              placeholder="Username"
              data-testid={Pages.MetricsEndpointIntegration.authParam('basic-username')}
              ref={register({
                required: true,
              })}
            />
          </Field>
          <Field
            label="Basic Password"
            invalid={errors.basicPassword !== undefined}
            error="Valid basic password is required"
          >
            <Input
              type="password"
              aria-label="password"
              id="password"
              name="basicPassword"
              placeholder="Password"
              data-testid={Pages.MetricsEndpointIntegration.authParam('basic-password')}
              ref={register({
                required: true,
              })}
            />
          </Field>
        </>
      ) : state.authenticationMethod === 'bearer' ? (
        <Field label="Bearer Token" invalid={errors.bearerToken !== undefined} error="Valid bearer token is required">
          <Input
            type="password"
            aria-label="bearer token"
            id="bearer_token"
            name="bearerToken"
            placeholder="Bearer Token"
            data-testid={Pages.MetricsEndpointIntegration.authParam('bearer-token')}
            ref={register({
              required: true,
            })}
          />
        </Field>
      ) : (
        <></>
      )}
      <ConnectionCheckButton
        onSuccess={() => onSuccess()}
        disabled={
          errors.url !== undefined ||
          (state.authenticationMethod === 'basic' && (!state.basicUsername || !state.basicPassword)) ||
          (state.authenticationMethod === 'bearer' && !state.bearerToken)
        }
        onCheckConnection={onCheckConnection}
        successMessage="All good! Successfully scraped metrics from URL"
      />
    </>
  );
};

export default AuthField;
