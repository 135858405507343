import { css } from '@emotion/css';
import React from 'react';
import { FieldErrors, UseFormMethods, Validate } from 'react-hook-form';

import { GrafanaTheme2 } from '@grafana/data';
import { Field, Input, useStyles2 } from '@grafana/ui';

import { FormErrors } from 'utils/consts';

const getStyles = (theme: GrafanaTheme2) => ({
  field: css`
    > div {
      max-width: none;
    }

    [class$='-Label'] {
      font-size: ${theme.typography.body.fontSize};
      font-weight: ${theme.typography.fontWeightRegular};
    }
  `,
});

interface ScrapeJobNameFieldProps {
  title?: string;
  description?: string;
  placeholder?: string;
  errors: FieldErrors;
  register: UseFormMethods['register'];
  validate?: Validate | Record<string, Validate>;
}

export const ScrapeJobNameField = ({
  title = 'Scrape job name',
  description,
  placeholder = 'my_scrape_job_name',
  register,
  errors,
  validate,
}: ScrapeJobNameFieldProps) => {
  const styles = useStyles2(getStyles);
  return (
    <Field
      label={title}
      description={description}
      invalid={errors.name !== undefined}
      error={errors.name?.message}
      className={styles.field}
    >
      <Input
        type="text"
        aria-label={title}
        placeholder={placeholder}
        id="name"
        name="name"
        ref={register({
          required: {
            value: true,
            message: FormErrors.REQUIRED_FIELD,
          },
          pattern: {
            value: /^[\w-]*$/,
            message: FormErrors.JOB_NAME_CHARACTERS,
          },
          validate,
        })}
      />
    </Field>
  );
};
