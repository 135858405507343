import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Field, Spinner, useStyles2 } from '@grafana/ui';

import { useCreateOrUpdateScrapeJobMutation } from 'api/hostedExporters/hostedExportersApi';
import { MetricsEndpointFlavor, SupabaseJob, SupabaseJobApiResponse } from 'models/api-models';
import { CREATE_OR_UPDATE_JOBS_CACHE_KEY } from 'utils/consts';

import { EditJobFormProps } from '../JobManager/JobManager';
import { JobStatusWidget } from '../JobManager/JobStatusWidget';
import { getJobFormStyles } from '../common/MetricsEndpointInstructions.styles';
import { ScrapeIntervalField } from '../common/ScrapeIntervalField';

import AuthField from './AuthField';

type ReactHookFormProps = {
  url: string;
  scrapeIntervalSeconds: number;
  name: string;
  apiKey: string;
  projectId: string;
};

const getDefaultValues = (job: SupabaseJob) => {
  if (typeof job === 'undefined') {
    return {
      apiKey: '',
      projectID: '',
      scrapeInterval: 60,
      url: '',
      name: '',
      flavor: MetricsEndpointFlavor.Supabase,
    };
  }

  return job;
};

export const EditJob: FC<EditJobFormProps<SupabaseJobApiResponse>> = ({ onSaveJob, job: jobApi }) => {
  const job = {
    scrapeIntervalSeconds: jobApi?.scrape_interval_seconds,
    apiKey: jobApi?.supabase.api_key,
    projectId: jobApi?.supabase.project_id,
    name: jobApi?.name,
    flavor: MetricsEndpointFlavor.Supabase,
  } as SupabaseJob;

  const styles = useStyles2(getJobFormStyles);
  const [_, { status: createJobStatus }] = useCreateOrUpdateScrapeJobMutation({
    fixedCacheKey: CREATE_OR_UPDATE_JOBS_CACHE_KEY,
  });

  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<ReactHookFormProps>({
    mode: 'onChange',
    defaultValues: getDefaultValues(job),
  });

  const [connectionSuccess, setConnectionSuccess] = useState(false);

  const handleEdit = handleSubmit((currentJob: SupabaseJob) => {
    onSaveJob({
      name: job.name || currentJob.name,
      scrape_interval_seconds: currentJob.scrapeIntervalSeconds,
      supabase: {
        api_key: currentJob.apiKey,
        project_id: currentJob.projectId,
      },
      enabled: job.enabled ?? true,
      flavor: MetricsEndpointFlavor.Supabase,
    });
  });

  return (
    <form onSubmit={handleEdit}>
      <Field label="Scrape Job Name" invalid={errors.name !== undefined} error={errors.name?.message}>
        <span>{job.name}</span>
      </Field>
      <div className={styles.job}>
        <ScrapeIntervalField control={control} />
        <AuthField
          register={register}
          control={control}
          errors={errors}
          watch={watch}
          onSuccess={() => setConnectionSuccess(true)}
        />
      </div>
      <JobStatusWidget />
      <Button
        variant="primary"
        type="submit"
        aria-label="Save Scrape Job"
        disabled={!connectionSuccess || createJobStatus === QueryStatus.pending}
      >
        {createJobStatus === QueryStatus.pending && <Spinner className={styles.spinner} />}
        Save Scrape Job
      </Button>
    </form>
  );
};
